.hero,
.h1,
.h2,
.h3,
.h4 {
  font-weight: 700;
  color: var(--n2);
}

.hero {
  font-size: 8rem;
  line-height: 1;
  letter-spacing: -0.02em;
}

.h1 {
  font-size: 64px;
  line-height: 1;
  letter-spacing: -0.02em;
}

.h2 {
  font-size: 48px;
  line-height: (56/48);
  letter-spacing: -0.02em;
}

.h3 {
  font-size: 40px;
  line-height: (48/40);
  letter-spacing: -0.01em;
}

.h4 {
  font-size: 32px;
  line-height: (40/32);
  letter-spacing: -0.01em;
}

@media (max-width: 768px) {
  .hero {
    font-size: 6rem;
    line-height: 1;
    letter-spacing: -0.02em;
  }
}

@media (max-width: 474px) {
  .hero {
    font-size: 4rem;
    line-height: (56/48);
    letter-spacing: -0.02em;
  }

  .h1 {
    font-size: 48px;
    line-height: (56/48);
    letter-spacing: -0.02em;
  }

  .h2 {
    font-size: 40px;
    line-height: (48/40);
    letter-spacing: -0.01em;
  }

  .h3 {
    font-size: 32px;
    line-height: (40/32);
    letter-spacing: -0.01em;
  }
}
