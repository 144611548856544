.section {
  right: 0;
  left: 0;
  /* border-bottom: 1px solid var(--n7); */
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
}

.break {
  width: 1px;
  height: 48px;
  background: var(--n7);
}

.main_wrapper {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 48px;
  align-items: center;
}

.mini_wrapper {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 32px;
  align-items: center;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(1, auto);
  grid-gap: 8px;
  align-items: center;
}

.logo {
  color: var(--n2);
  font-weight: bold;
}

.logo span {
  color: var(--p1);
}
.logo img {
  height: 5rem;
  padding-top: 1rem;
}


.responsive_header {
  display: none;
}

.responsive_button {
  display: none;
}

.marketing {
  display: grid;
  grid-template-columns: repeat(1, auto);
  grid-gap: 8px;
  align-items: center;
}


.social_wrapper {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
}

.social_icon {
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.social_icon:hover {
  transform: translateY(-4px);
}


@media (max-width: 768px) {
  .wrapper {
    grid-template-columns: repeat(1, auto);
  }
  .responsive_header {
    display: block;
    position: fixed;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 9999;
  }

  .main_wrapper {
    grid-gap: 32px;
  }

  .mini_wrapper {
    display: none;
  }

  .menu {
    display: grid;
    grid-template-columns: repeat(3, auto);
    background-color: var(--n8);
    padding: 6px;
    border-radius: 9999px;
    grid-gap: 8px;
  }
  .logo img {
    height: 3rem;
    padding-top: 0rem;
  }

  .menu_item {
    padding: 8px;
    border-radius: 9999px;
  }

  .responsive_button {
    display: block;
  }

  .marketing {
    /* position: fixed; */
    z-index: 99;
    bottom: 20px;
    right: 20px;
  }
}
