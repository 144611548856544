[class~='button'] {
  background-color: var(--n2);
  padding: 16px 24px;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  color: var(--n8);
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  font-family: 'Outfit', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  outline: none;
}

.button:hover {
  background-color: darken(--p1, 10);
}

.button:active {
  transform: scale(0.9);
}

.button-stroke {
  background-color: var(--n8);
  color: var(--n2);
  box-shadow: inset 0 0 0 2px var(--n6);
}

.button-stroke:hover {
  box-shadow: inset 0 0 0 2px var(--n2);
}

.button-small {
  color: var(--n4);
  background-color: transparent;
  font-weight: bold;
}

.button-small:hover {
  color: var(--n2);
  opacity: 1;
  background-color: transparent;
}

.active {
  color: var(--n2);
}

.button-svg {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--n2);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  outline: none;
}

.button-svg:hover,
.button-svg:active {
  background: var(--p1);
}

.button-svg:hover svg,
.button-svg:active svg {
  fill: var(--n8);
}

.button-svg svg {
  fill: var(--n8);
}

.svg-active {
  background: var(--p1);
}

.svg-active svg {
  fill: var(--n8);
}

@media (max-width: 768px) {
  .button {
    width: 100%;
  }
}
