.section {
  padding-top: 90px;
}

.container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  grid-gap: 32px;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.wrapper {
  max-width: 600px;
}

.image_background {
  width: 455px;
  height: 455px;
  background-color: var(--n8);
  border-radius: 50%;
  padding: 40px;
  border: 1px solid var(--n6);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play_button {
  position: absolute;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    83.59deg,
    #fcfcfd 36.52%,
    rgba(252, 252, 253, 0.83) 98.8%
  );
  box-shadow: 0px 40px 64px -32px rgba(15, 15, 15, 0.1);
  backdrop-filter: blur(32px);
  border-radius: 50%;
}

.play_button svg {
  fill: var(--n4);
}

.play_button:hover svg {
  fill: var(--n2);
}

.image {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: fadeUp 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.title {
  margin-bottom: 24px;
  /* font-size: 8rem; */
  background-image: linear-gradient(
    to right,
    /* #e04c24, */
    #e04c24,
    #733935,
    #762f43,
    #4d324d,
    #32434a,
    /* #e04c24, */
    #e04c24
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow-animation 850s linear infinite;
}
@keyframes rainbow-animation {
  to {
    background-position: 4500vh;
  }
}

.social_wrapper {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 24px;
}

.social_icon {
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.social_icon:hover {
  transform: translateY(-4px);
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .section {
    padding-bottom: 0;
  }

  .container {
    grid-template-columns: repeat(1, auto);
  }

  .image_background {
    margin-top: 24px;
    margin-bottom: 32px;
    width: 100%;
    height: 350px;
    border-radius: 9999px;
    padding: 32px;
  }

  .image {
    border-radius: 9999px;
  }
}

@media (max-width: 474px) {
  .title {
    font-size: 48px;
    line-height: 56px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 24px;
  }
}
