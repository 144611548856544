.container {
  padding: 0 50px;
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
}

@media (max-width: 768px) {
  .container {
    padding: 0 40px;
  }
}

@media (max-width: 474px) {
  .container {
    padding: 0 32px;
  }
}
