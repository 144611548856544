.section {
  padding-top: 64px;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 32px;
  margin-top: 48px;
  padding-bottom: 48px;
  position: relative;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -12px -6px 0;
}

.button {
  align-self: center;
  margin: 12px 6px 0;
  padding: 6px 12px;
  border-radius: 14px;
  background: none;
  color: var(--n4);
  font-family: "Outfit", sans-serif;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
}

.button:hover {
  color: var(--n2);
}

.active {
  /* background: linear-gradient(
    to right,
    #e04c24,
    #733935,
    #762f43,
    #4d324d,
    #32434a,
    #e04c24
  ); */
  background-image: linear-gradient(
    to right,
    /* #e04c24, */ #733935,
    #733935,
    #762f43,
    #762f43,
    #4d324d,
    #4d324d,
    #32434a,
    #32434a,
    /* #e04c24, */ #733935
  );
  /* color: var(--n8); */
  -webkit-background-clip: content-box,;
  /* -webkit-text-fill-color: transparent; */
  animation: rainbow-animation 4000s linear infinite;
}
@keyframes rainbow-animation {
  to {
    background-position: 4500vh;
  }
}

.active:hover {
  /* color: var(--n8); */
}

.dropdown {
  display: none;
}

@media (max-width: 768px) {
  .wrapper {
    grid-template-columns: repeat(2, auto);
  }

  .dropdown {
    display: block;
  }

  .nav {
    display: none;
  }
}

@media (max-width: 474px) {
  .wrapper {
    grid-template-columns: repeat(1, auto);
  }
}
