.dropdown {
  position: relative;
  z-index: 3;
}

.active {
  z-index: 10;
}

.active .head .arrow svg {
  transform: rotate(180deg);
}

.active .body {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.head {
  position: relative;
  height: 48px;
  padding: 0 48px 0 16px;
  border-radius: 12px;
  background: var(--n8);
  box-shadow: inset 0 0 0 2px var(--n6);
  font-size: 14px;
  font-weight: 500;
  line-height: 48px;
  color: var(--p1);
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: box-shadow 0.2s;
}

.head:hover {
  box-shadow: inset 0 0 0 2px var(--n4);
}

.selection {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.arrow {
  position: absolute;
  top: 50%;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px var(--n6);
  transform: translateY(-50%);
}

.arrow svg {
  fill: var(--n4);
  transition: transform 0.2s;
}

.body {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  right: 0;
  padding: 8px 0;
  border-radius: 12px;
  background: var(--n8);
  box-shadow: inset 0 0 0 2px var(--n6), 0 4px 12px rgba(var(--n2), 0.1);
  visibility: hidden;
  opacity: 0;
  transform: translateY(3px);
  transition: all 0.2s;
}

.option {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  transition: color 0.2s;
  cursor: pointer;
  color: aliceblue;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-radius: 6px;
}

.option:hover {
  color: var(--p1);
}

.selectioned {
  color: var(--p1);
}
